/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// from react-rails gem
// Support component names relative to this directory:
var componentRequireContext = require.context("../components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
window.componentRequireContext = componentRequireContext;

// console.log('Hello World from Webpacker')
import '../app-styles'
import 'video.js'
//import RailsUJS from '@rails/ujs'
//import Rails from '@rails/ujs'

// Not sure why this isn't running automatically, but.
import axios from 'axios';
import domready from 'domready';

// TODO: move this into a separate pack?
require.context('../images', true)

import '../images/homepage-hero-950.png'
import '../images/family-photo-icon.png'
import '../images/devices-icon.png'
import '../images/privacy-icon.png'
import '../images/our-family.jpg'
import '../images/create-album.png'
import '../images/receive-photo-email.png'
import '../images/upload-photos.png'
import '../images/invite-partner.png'
import '../images/done.png'

// rollbar action
var _rollbarConfig = {
  accessToken: "3f06ac22acf146e5aa751590f1ec51df",
  captureUncaught: true,
  payload: {
     environment: process.env.RAILS_ENV,
  }
};
var rollbar = require('../rollbar.umd.min.js');
var Rollbar = new rollbar(_rollbarConfig);

// see https://github.com/rails/rails/tree/master/actionview/app/assets/javascripts
// Rails.start();

domready(() => {
  //ReactRailsUJS.mountComponents();
  let token = document.querySelector('meta[name="csrf-token"]').content;
  axios.defaults.headers.common = {
    'X-CSRF-Token': token,
    'X-Requested-With': 'XMLHttpRequest',
  };
});
