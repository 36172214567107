import React, { Component } from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone'
import S3Upload from 'react-s3-uploader/s3upload';

// the plan
// - turn progress into an array of %ages
// - also have an array of boolean for files complete, indexed by name (?)
// - when all filesCompleted === true, then we're good to go

var _rollbarConfig = {
  accessToken: "3f06ac22acf146e5aa751590f1ec51df",
  captureUncaught: false,
  payload: {
     environment: process.env.RAILS_ENV,
  }
};
var rollbar = require('../rollbar.umd.min.js');
var Rollbar = new rollbar(_rollbarConfig);

class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      progress: {},
      completed: {},
      showProgressBar: false,
      inOnboarding: this.props.in_onboarding,
      uploadFilename: null,
      // shitty done state. todo: clean this up.
      // really goes from no progress bar -> progress bar for each file -> done
      done: false,
      debug: this.props.debug,
    };
  }

  onDrop(files) {
    files = files.slice(0, 10);
    this.setState({ files });
    let options = {
      files: files,
      contentDisposition: 'attachment',
      // this is FUCKED
      uploadRequestHeaders: 'asdf',
      signingUrl: '/s3/sign',
      onFinishS3Put: (result, file) => this.handleFinish(result, file),
      onProgress: (percent, message, file) => this.handleProgress(percent, message, file),
      onError: (error) => this.handleError(error),
    };
    new S3Upload(options);

    // set progress bar to zero for every file.
    let emptyProgress = {};
    for (var file of files) { emptyProgress[file.name] = 0; }

    this.setState({
      showProgressBar: true,
      progress: emptyProgress,
    });
  }

  handleError(error) {
    Rollbar.error(error);
    alert('Error -- please contact help@famgram.com if you keep seeing this error.');
  }

  handleProgress(percent, message, file) {
    this.setState((prevState, props) => {
      let progress = prevState.progress;
      progress[file.name] = percent;
      return { progress: progress };
    });
  }

  handleFinish(result, file) {
    let url = result.signedUrl.split('?')[0];
    if (this.state.debug) { console.log(url); }
    axios.post(
      '/posts',
      {
        post: {
          group_id: this.props.group_id,
          photo_url: url,
          content_type: file.type,
        },
        multi: this.state.files.length > 1,
        in_onboarding: this.state.inOnboarding,
      }
    ).then((response) => {
      this.setState((prevState, props) => {
        let completed = prevState.completed;
        completed[file.name] = true;
        return { completed: completed };
      }, () => {
        // this is our setState callback
        let completedCount = 0;
        for (var file of this.state.files) {
          if (this.state.completed[file.name]) {
            completedCount++;
          }
        }

        if (this.state.debug) {
          console.log(completedCount + ' of ' + this.state.files.length + ' files completed');
        }

        if (completedCount === this.state.files.length) {
          window.location.href = response.data.redirect_url;
          this.setState({showProgressBar: false, done: true});
        }
      });
    });
  }

  onError(message) {
    alert(message);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    let progressBar = false;
    if (this.state.showProgressBar) {
      progressBar = this.state.files.map((file) => {
        return (<div key={file.name} className="mb3">
          Uploading {file.name} &hellip;
          <div className="bg-moon-gray br-pill h1 overflow-y-hidden">
            <div className="bg-blue br-pill h1 shadow-1" style={ { width: this.state.progress[file.name] + '%'} }></div>
          </div>
        </div>);
      });
    } else if (this.state.done) {
      progressBar = (<div className='tc f4'>
        <icon className='icon icon-spinner pr2'></icon>
        Finishing...
      </div>);
    }

    return (
      <section>
        <div className="dropzone">
          {!progressBar &&
          (<Dropzone
            onDrop={this.onDrop.bind(this)}
            accept="video/*, image/*"
            className='w-100 bg-blue white ba br2 b--silver pa1 tc pointer'
            multiple={true}
          >
            <div className='mv3'>Click to select up to 10 photos/videos, or drag &amp; drop here</div>
          </Dropzone>) }
          {progressBar ? progressBar : ''}
        </div>
      </section>
    );
  }
}

export default UploadForm;
