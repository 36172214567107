import React, { Component } from 'react';
import videojs from 'video.js';
import axios from 'axios';
import Modal from 'react-modal';

// see https://github.com/nmn/react-timeago
import TimeAgo from 'react-timeago'
import enShortStrings from 'react-timeago/lib/language-strings/en-short'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const timeAgoformatter = buildFormatter(enShortStrings)

// TODO: share modal among all posts?
class PostFooter extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      modalOpen: false
    });
  }

  showModal(e) {
    e.preventDefault();
    this.setState({modalOpen: true});
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({modalOpen: false});
  }

  clickDeletePost(e) {
    e.preventDefault();
    if (!confirm('Are you sure you want to delete this?')) {
      return;
    }
    axios.delete(
      '/posts/' + this.props.id,
    ).then((response) => {
      window.location.reload(false);
    });
  }

  render() {
    let buttons = [];
    let emailHref = 'mailto:?subject=' +
                    encodeURIComponent('Check out this photo') +
                    '&body=' +
                    encodeURIComponent(this.props.url + "\n\nSent via Famgram (https://famgram.com)");
    buttons.push(<ActionButton key='1' text='Share via email' href={emailHref}/>);
    buttons.push(<ActionButton key='2' text='Download original' href={this.props.originalUrl}/>);
    buttons.push(<ActionButton key='3' text='Link' href={this.props.url}/>);
    if (this.props.permissions.destroy) {
      buttons.push(
        <div key='4' onClick={(e) => this.clickDeletePost(e)}>
          <ActionButton key='4' text='Delete' href='#'/>
        </div>
      );
    }

    let commentForm = '';
    if (this.props.permissions.comment) {
      commentForm = <CommentForm id={this.props.id} refreshFromServer={this.props.refreshFromServer} />;
    }

    return (
      <div>
        <div className='cf'>
          <div className='w-10-ns pr2 pr0-ns fl'>
            <LikeButton liked={this.props.liked} toggleLike={this.props.toggleLike}/>
          </div>
          <div className='w-80 fl db-ns dn'>
            {commentForm}
          </div>
          <div className='w-10-ns fr pl2 pl0-ns tr'>
            <i className='fa-lg moon-gray pointer fa-solid fa-ellipsis-vertical pt2' onClick={(e) => this.showModal(e)}></i>
            <Modal
              isOpen={this.state.modalOpen}
              contentLabel='actionSheet'
              shouldCloseOnOverlayClick={true}
              onRequestClose={(e) => this.closeModal(e)}
              className='vh-100 dt w-100'
              style={{
                overlay: {
                  backgroundColor:'rgba(0,0,0,0.75)'
                },
              }}
            >
              <div className='dtc v-mid tc white ph3 ph4-l'>
                <div className='bg-white h5 w-60-ns w-90 center pa4 relative'>
                  <div className='absolute top-0 right-0 gray w2 pvs tc pointer' onClick={(e) => this.closeModal(e)}>
                    <i className='icon fa-solid fa-xmark f3'></i>
                  </div>
                  {buttons}
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className='w-100 dn-ns db mt2'>
          {commentForm}
        </div>
      </div>
    );
  }
}

class LikeButton extends Component {
  render() {
    let sharedClasses = 'icon icon-2x moon-gray pointer ';
    let iconClass;
    if (this.props.liked) {
      iconClass = sharedClasses + 'fa-icon fa-solid dark-red fa-heart fa-lg pt2';
    } else{
      iconClass = sharedClasses + 'fa-icon fa-regular fa-heart fa-lg pt2';
    }
    return (
      <div onClick={(e) => this.props.toggleLike(e)}>
        <i className={iconClass}></i>
      </div>
    );
  }
}

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    axios.post(
      '/comments',
      {
        comment: {
          post_id: this.props.id,
          body: this.state.value,
        },
      },
    ).then((response) => {
      this.setState({ value: '' });
      this.props.refreshFromServer();
    });
  }

  render() {
    return (
      <form
        onSubmit={(e) => this.handleSubmit(e)}
        onChange={(e) => this.handleChange(e)}
        className='db w-100'
      >
        <input
          type='text'
          name='body'
          placeholder='Write a comment...'
          autoComplete='off'
          maxLength='2048'
          value={this.state.value}
          className='input-reset ba b--light-gray pa2 br2 dib w-75 pr3 fl w-100-ns'
        />
        <input type='submit' className='br1 bg-blue white ba b--dark-blue ph2 mn2 w-20 dn-ns fr ml1' value='Post' />
        <div className='cf'></div>
      </form>
    );
  }
}

// expects text and an href
class ActionButton extends Component {
  render() {
    return (
      <div className='h2 mb3'>
        <a
          className='br1 bg-light-gray ba b--moon-gray pv2 ph3 link mid-gray v-mid dib pointer w5'
          href={this.props.href}
          target='_blank'
        >
          {this.props.text}
        </a>
      </div>
    )
  }
}

class PostHeader extends Component {
  render() {
    return (
      <div className='ba b--light-gray w-100 cf pa3'>
        <div className='w-50 fl'>
          {this.props.name}
        </div>
        <div className='w-50 fl tr'>
          <TimeAgo date={this.props.date} formatter={timeAgoformatter} />
        </div>
      </div>
    );
  }
}

class Photo extends Component {
  render() {
    return (
      <div className='pa0 ma0 lh-none'>
        <img src={this.props.photo.url} />
      </div>
    );
  }
}

class Video extends Component {
  componentDidMount() {
    this.player = videojs(this.videoPlayer);
    this.player.ready(() => {
      // log plays
      this.player.on('ended', () => {
        // TODO: log plays w/o Ahoy jQuery dependency
        this.player.bigPlayButton.show()
      });
    });
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  // anonymous function to resize players so the addEventListener calls
  // will have a reference to the Video rather than the window.
  // source: https://stackoverflow.com/questions/19014250/reactjs-rerender-on-browser-resize
  // seems like there is some disagreement as to the right way to do this.
  resize = () => this.resizePlayers();

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resizePlayers() {
    if (this.videoPlayer === undefined) {
      console.log('no reference to video player');
      return;
    }
    if (!this.videoPlayer.parentNode ||
        !this.videoPlayer.parentNode.parentNode) {
      // some weird state
      console.log("video player has no parent node");
      return;
    }
    // TODO: this seems pretty tied to the DOM, which may not be correct
    let desiredWidth = this.videoPlayer.parentNode.parentNode.clientWidth;
    let aspectRatio = this.props.video.width / this.props.video.height;
    let desiredHeight = desiredWidth * (1 / aspectRatio);
    this.player.width(desiredWidth);
    this.player.height(desiredHeight);
  }

  render() {
    return (
      <div className='pa0 ma0 lh-none'>
        <video
          id={this.props.id}
          className='video-js vjs-big-play-centered'
          controls
          preload='none'
          poster={this.props.video.preview_url}
          height={this.props.video.height}
          width={this.props.video.width}
          ref={(c) => { this.videoPlayer = c; }}
          data-setup='{ "controlBar": { "muteToggle": false } }'
        >
          <source
            src={this.props.video.url}
            type='video/mp4'
          />
        </video>
      </div>
    );
  }
}

class LikesContainer extends Component {
  render() {
    if (this.props.likers.length === 0) {
      return null;
    }

    // map likes to html
    let likes = this.props.likers.map((liker, index) => {
      let lastElement = (index == this.props.likers.length - 1);
      let padding = lastElement ? '' : 'pr2';
      let classes = 'dib ' + padding;
      return (
        <li className={classes} key={liker.id}>
          {liker.first_name + (lastElement ? '' : ', ')}
        </li>
      );
    });

    return (
      <div>
        <ul className='dib pl0 list mt0'>
          <li className='dib pr2' key='heart'><i className='fa-solid fa-icon fa-heart moon-gray pt2'></i></li>
          {likes}
        </ul>
      </div>
    );
  }
}

class CommentsContainer extends Component {
  render() {
    if (this.props.comments.length === 0) {
      return null;
    }

    let comments = this.props.comments.map((comment, index) => {
      return (
        <Comment
          key={comment.id}
          comment={comment}
          className='mb2'
          refreshFromServer={this.props.refreshFromServer} />
      );
    });

    return (
      <ul className='pa0 mt0 mb3 list'>
        {comments}
      </ul>
    );
  }
}

class Comment extends Component {
  onDelete(e) {
    e.preventDefault();
    if (confirm('Are you sure you want to delete this comment?')) {
      axios
        .delete(
          '/comments/' + this.props.comment.id
        ).then((response) => {
          this.props.refreshFromServer();
        });
    }
  }

  render() {
    let deleteButton = '';
    if (this.props.comment.permissions.destroy) {
      deleteButton = (
        <div className='pointer fr w2 gray glow o-10 tr' onClick={(e) => this.onDelete(e)}>
          <i className='icon icon-times'></i>
        </div>
      );
    }
    return (
      <li className='cf hide-child mb2'>
        <strong className='dib pr2'>{this.props.comment.user.first_name}</strong>
        {this.props.comment.body}
        {deleteButton}
      </li>
    );
  }
}

class PostContainer extends Component {
  toggleLike() {
    let isLiked = this.props.toggleLike();
    // also do the animation here
    if (isLiked && !this.heartIcon.classList.contains('js-liked')) {
      this.heartIcon.classList.add('js-liked');
    }
  }

  render() {
    let attachment;
    if (this.props.attachment.type == 'photo') {
      attachment = <Photo photo={this.props.attachment} id={this.props.id} />
    } else {
      attachment = <Video video={this.props.attachment} id={this.props.id} />
    }
    return (
      <div className='pa0 ma0 lh-none' onDoubleClick={() => this.toggleLike()}>
        {attachment}
        <i className='icon icon-heart icon-2x dark-red heart-animation' ref={(i) => { this.heartIcon = i; }}></i>
      </div>
    );
  }
}

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likers: this.props.likers.slice(),
      comments: this.props.comments.slice(),
    };
    this.toggleLike = this.toggleLike.bind(this);
  }

  toggleLike(e) {
    if (!this.props.current_user) {
      return;
    }

    // fire off to the server
    axios.post('/likes/toggle', { post_id: this.props.id });

    // update state of client
    let likers = this.state.likers.slice();
    let liked;
    // going from liked -> disliked?
    if (this.isLiked()) {
      likers = likers.filter((like) => {
        if (like.id != this.props.current_user.id) {
          return like;
        } else {
          return null;
        }
      });
      liked = false;
    } else { // disliked -> liked
      likers.push(this.props.current_user);
      liked = true;
    }
    this.setState({ likers: likers });
    return liked;
  }

  isLiked() {
    if (!this.props.current_user) {
      return false;
    }

    // compute liked from state
    let liked_ids = this.state.likers.slice().map((user) => {
      return user.id;
    });
    return liked_ids.includes(this.props.current_user.id);
  }

  refreshFromServer() {
    axios
      .get(this.props.url)
      .then((response) => {
        this.setState({
          likers: response.data.post.likers.slice(),
          comments: response.data.post.comments.slice(),
        });
      });
  }

  render() {
    let showBorderAboveFooter = this.state.likers.length > 0 || this.state.comments.length > 0;
    return (
      <div className='mb5 bg-white'>
        <a name={this.props.id}></a>
        <PostHeader name={this.props.user.first_name} date={this.props.created_at} />
        <PostContainer
          attachment={this.props.attachment}
          id={this.props.id}
          toggleLike={() => this.toggleLike()}
        />

        <div className='br bl bb b--light-gray lh-solid pa3'>
          <LikesContainer likers={this.state.likers} />
          <CommentsContainer
            comments={this.state.comments}
            refreshFromServer={() => this.refreshFromServer()}
          />
          <div className={showBorderAboveFooter ? 'mt1 pt3 bt b--light-gray' : 'lh-none'}>
            <PostFooter
              liked={this.isLiked()}
              id={this.props.id}
              originalUrl={this.props.attachment.original_url}
              url={this.props.url}
              toggleLike={() => this.toggleLike()}
              refreshFromServer={() => this.refreshFromServer()}
              permissions={this.props.permissions}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Post;
